import { Route, Routes,BrowserRouter } from "react-router-dom"
import Home from "./Pages/Home";
import Processando from "./Pages/Processando"
import Celular from "./Pages/Celular"
import Provas from "./Pages/Provas";
import Chat from "./Pages/Chat";
import Assinatura from "./Pages/Assinatura";


function App() {
  return (
   <BrowserRouter>
    <Routes>

<Route path="/"   element = {<Home/> } />
<Route path="/processando/:telefone/:select"   element = { <Processando/> } />
<Route path="/celular/:telefone"   element = { <Celular/> } />
<Route path="/provas/:telefone/:select"   element = { <Provas/> } />
<Route path="/chat/:telefone"   element = { <Chat/> } />
<Route path="/assinatura"   element = { <Assinatura/> } />


   </Routes>
   </BrowserRouter>
  );
}

export default App;
