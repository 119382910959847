import "./assinatura.css"
import { useParams } from "react-router-dom";
import Heade from '../../Components/Header'
import { useEffect, useState } from "react";
import useGeoLocation from "react-ipgeolocation";
import { Link } from 'react-router-dom';
import { loadStripe } from "@stripe/stripe-js";


 function Assinatura() {
  const location = useGeoLocation();
  const {telefone}= useParams();
  const [foto,setFoto] = useState("")
  const [loading,setLoading] = useState(true)
  const [city,setCity] = useState('')
  const [region,setRegion] = useState('')

  useEffect(()=>{

    


  })

  async function handleCheckout(){

 const stripe = await loadStripe('pk_live_51OaXS4CXPtVkQR0dZNg4NwjrnRCLcpH4tVbJ3a5N0GXLaWrYCglNYHzsxK5dgbAd6UlKtz43bGvm60dHfnZ1DqrA00bAyIuzdq');
     const { error } = await stripe.redirectToCheckout({
    
       lineItems:[
         {
           price:'price_1QJ2h9CXPtVkQR0dLkvPkDcB',
           quantity:1,
         },
       ],
       mode: 'payment',
      
       successUrl: 'https://viewerspy.netlify.app/sucess/{CHECKOUT_SESSION_ID}',
       cancelUrl: 'https://viewerspy.netlify.app/cancel',
  
     })
    
     console.warn(error.message)
  
   /*
    if(valorP=="59"){
   
     window.open('https://go.perfectpay.com.br/PPU38COP9AG')
   
   
    }
    else if(valorP==139){
    
     window.open('https://go.perfectpay.com.br/PPU38COP97N')
    }
    else if(valorP==329){
    
     window.open('https://go.perfectpay.com.br/PPU38COP98N')
   
    }
   
   */
   }




  return (
    <div className="contCelular">
      <Heade/>

<div className="boxmenss">

    <div className="mens">
        <h1>Su suscripción a  <b>ViewerSpy</b> es<br></br>
   casi <b>completo.</b>
        </h1>
       
        <h1><b>Suscríbete hoy</b> y desbloquea <br/>
        <b>¡Inmediatamente </b>todas las <b>características exclusivas </b> de <b>ViewerSpy!</b>
        </h1>
    </div>

    <div className="mensa">
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="rocket" class="svg-inline--fa fa-rocket min-w-6 min-h-6" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="24" height="24"><path fill="currentColor" d="M156.6 384.9L125.7 354c-8.5-8.5-11.5-20.8-7.7-32.2c3-8.9 7-20.5 11.8-33.8L24 288c-8.6 0-16.6-4.6-20.9-12.1s-4.2-16.7 .2-24.1l52.5-88.5c13-21.9 36.5-35.3 61.9-35.3l82.3 0c2.4-4 4.8-7.7 7.2-11.3C289.1-4.1 411.1-8.1 483.9 5.3c11.6 2.1 20.6 11.2 22.8 22.8c13.4 72.9 9.3 194.8-111.4 276.7c-3.5 2.4-7.3 4.8-11.3 7.2v82.3c0 25.4-13.4 49-35.3 61.9l-88.5 52.5c-7.4 4.4-16.6 4.5-24.1 .2s-12.1-12.2-12.1-20.9V380.8c-14.1 4.9-26.4 8.9-35.7 11.9c-11.2 3.6-23.4 .5-31.8-7.8zM384 168a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"></path></svg>
       <h1>Acceso inmediato</h1>
        <p>
        comience a usar la aplicación tan pronto como <br/>
        inscribirse. Sin espera, <br/>
        sin complicaciones.
        </p>
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrows-rotate" class="svg-inline--fa fa-arrows-rotate min-w-6 min-h-6" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="24" height="24"><path fill="currentColor" d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160H352c-17.7 0-32 14.3-32 32s14.3 32 32 32H463.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V80c0-17.7-14.3-32-32-32s-32 14.3-32 32v35.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V432c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352H160c17.7 0 32-14.3 32-32s-14.3-32-32-32H48.4c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z"></path></svg>
        <h1>Actualizar constantes</h1>
        <p>
        Manténgase a la vanguardia con acceso a <br/>
        todas las últimas actualizaciones.<br/>
          
        </p>
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="screwdriver-wrench" class="svg-inline--fa fa-screwdriver-wrench min-w-6 min-h-6" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="24" height="24"><path fill="currentColor" d="M78.6 5C69.1-2.4 55.6-1.5 47 7L7 47c-8.5 8.5-9.4 22-2.1 31.6l80 104c4.5 5.9 11.6 9.4 19 9.4h54.1l109 109c-14.7 29-10 65.4 14.3 89.6l112 112c12.5 12.5 32.8 12.5 45.3 0l64-64c12.5-12.5 12.5-32.8 0-45.3l-112-112c-24.2-24.2-60.6-29-89.6-14.3l-109-109V104c0-7.5-3.5-14.5-9.4-19L78.6 5zM19.9 396.1C7.2 408.8 0 426.1 0 444.1C0 481.6 30.4 512 67.9 512c18 0 35.3-7.2 48-19.9L233.7 374.3c-7.8-20.9-9-43.6-3.6-65.1l-61.7-61.7L19.9 396.1zM512 144c0-10.5-1.1-20.7-3.2-30.5c-2.4-11.2-16.1-14.1-24.2-6l-63.9 63.9c-3 3-7.1 4.7-11.3 4.7H352c-8.8 0-16-7.2-16-16V102.6c0-4.2 1.7-8.3 4.7-11.3l63.9-63.9c8.1-8.1 5.2-21.8-6-24.2C388.7 1.1 378.5 0 368 0C288.5 0 224 64.5 224 144l0 .8 85.3 85.3c36-9.1 75.8 .5 104 28.7L429 274.5c49-23 83-72.8 83-130.5zM56 432a24 24 0 1 1 48 0 24 24 0 1 1 -48 0z"></path></svg>
        <h1>Soporte personalizado</h1>
        <p>
        Tenemos un equipo listo para<br/>
        ayudar con la instalación y optimizar su '<br/>
        experiencia.
        
        </p>
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shield-halved" class="svg-inline--fa fa-shield-halved min-w-6 min-h-6" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="24" height="24"><path fill="currentColor" d="M256 0c4.6 0 9.2 1 13.4 2.9L457.7 82.8c22 9.3 38.4 31 38.3 57.2c-.5 99.2-41.3 280.7-213.6 363.2c-16.7 8-36.1 8-52.8 0C57.3 420.7 16.5 239.2 16 140c-.1-26.2 16.3-47.9 38.3-57.2L242.7 2.9C246.8 1 251.4 0 256 0zm0 66.8V444.8C394 378 431.1 230.1 432 141.4L256 66.8l0 0z"></path></svg>
        <h1>Garantía de 15 días</h1>
        <p>
        Usa y disfruta la aplicación con total <br/>
        seguridad. Si no cumple con su<br/>
        expectativas, garantizamos un reembolso.       
        </p>
    </div>


<h1>Completa tu suscripción <br/>
ViewerSpy con un solo pago<br/>
único para garantizar el acceso inmediato<br/>
y comenzar a monitorear<br/>
en tiempo real.
</h1>


</div>

<Link onClick={handleCheckout} className="link"><span>Para firmar ViewerSpy</span></Link>

 
        
    </div>
      );
}
 

export default Assinatura;